export const NAV_TABS = [
  {
    url: "source-form-builder/groups",
    title: "Source Form Builder",
  },
  {
    url: "tax-forms/folders",
    title: "Tax Form Builder",
  },
  {
    url: "question-bank",
    title: "Question Bank",
  },
  {
    url: "shared-values",
    title: "Reusable Values",
  },
  {
    url: "importer",
    title: "Importer",
  },
  {
    url: "reports/list",
    title: "Report Builder",
  },
];

export const determineCurrent = (hash) => {
  return NAV_TABS.reduce((carry, curr) => {
    return hash.indexOf(curr.url) > -1 ? curr.url : carry;
  }, null);
};

export const flattenRevisions = (versions) => {
  return versions.reduce((carry, curr) => {
    return carry.concat(
      curr.revisions.map((revision) => {
        const date = formatDate(revision.creationDate);
        return {
          readableName: `${curr.name} - ${date}`,
          buttonLabel: revision.name,
          urlFormat: `${curr.id}-${revision.id}`,
          versionId: curr.id,
          product: curr.product,
        };
      })
    );
  }, []);
};

export const formatDate = (stamp) => {
  const time = new Date(stamp);
  return `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear()}`;
};

export const selectedRevision = (revisions, selected) => {
  return revisions.length > 0
    ? revisions.reduce((carry, curr) => {
        return curr.urlFormat === selected ? curr : carry;
      })
    : false;
};

export const getEndUserAppUrl = ({ origin }) => {
  const match = origin.match(
    /(https:\/\/)([A-Za-z0-9]+)-([A-Za-z0-9]+)\.canopy\.ninja/
  );

  if (origin === "https://skymonkey.canopytax.com") {
    return "https://app.canopytax.com";
  } else if (match) {
    return `https://app-${match[3]}.canopy.ninja`;
  }

  return "";
};

export const isTaxPrep = (pref, revisions) =>
  !!revisions.filter(
    (revision) => revision.urlFormat == pref && revision.product == "taxPrep"
  ).length;

export const getDraft = (versions, smeProduct) => {
  const product = smeProduct ? smeProduct : "taxPrep";

  const revision = versions.reduce((latest, version) => {
    if (version.product !== product) {
      return latest;
    }

    return version.revisions.reduce(
      (acc, curr) =>
        curr.status !== "workInProgress"
          ? acc
          : !acc || curr.creationDate > acc.creationDate
          ? curr
          : acc,
      latest
    );
  }, null);

  return `${revision.version}-${revision.id}`;
};
