import React from "react";
import ReactDOMClient from "react-dom/client";
import NavbarContainer from "./navbar.container.js";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { getSofePath } from "canopy-urls!sofe";
import { ReplaySubject } from "rxjs";

__webpack_public_path__ = getSofePath("sme-navbar"); // eslint-disable-line

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: NavbarContainer,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  featureToggles: [],
  childAppName: "sme-navbar",
  mainContentTransition: false,
  hotload: {
    dev: {
      enabled: true,
    },
  },
});

export {
  smeUser,
  getWorkspacesInReview,
  changeActiveWorkspace,
  patchSmeUser,
} from "./resources/sme-user.resource.js";

export { currentVersion } from "src/resources/versions.resource.js";
export { getEndUserAppUrl } from "./navbar.helpers.js";

export const smeNavHeight = new ReplaySubject(1);
smeNavHeight.next("60px");

export const bootstrap = [
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [canopyLifecycles.mount, reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("cp-sme-navbar");
  if (!el) {
    el = document.createElement("div");
    el.setAttribute("id", "cp-sme-navbar");
    document.body.insertBefore(el, document.body.childNodes[0]);
  }
  return el;
}
