import React from "react";
import { HiZ } from "src/sme-menu.component.js";
import { selectedRevision } from "src/navbar.helpers.js";
import { SelectContext } from "src/selectContext.js";

export default class VersionSelector extends React.Component {
  state = {};

  render() {
    const dropName = "version";

    const dropdownStatus =
      this.props.activeDropdown === dropName ? "open" : "closed";

    const scrollableStyle = {
      overflow: "auto",
      maxHeight: "800px",
    };

    return (
      <SelectContext.Consumer>
        {prefix => (
          <ul className="cps-list-unstyled cps-pull-right">
            <li
              onClick={() => this.props.setDropdown(dropName)}
              className={`cps-dropdown cps-${dropdownStatus}`}
            >
              <a className="cps-btn +primary cps-white">
                {selectedRevision(this.props.revisions, prefix)
                  ? selectedRevision(this.props.revisions, prefix).buttonLabel
                  : "Version"}{" "}
                <span className="cps-caret" />
              </a>
              <HiZ
                style={scrollableStyle}
                className="cps-dropdown-menu cps-pull-right"
                role="menu"
              >
                {this.props.revisions.map((version, i) => {
                  return (
                    <li key={version.versionId + i}>
                      <a
                        onClick={() =>
                          this.props.selectIt(
                            version.versionId,
                            version.readableName,
                            version.urlFormat
                          )
                        }
                      >
                        {version.buttonLabel}
                      </a>
                    </li>
                  );
                })}
              </HiZ>
            </li>
          </ul>
        )}
      </SelectContext.Consumer>
    );
  }
}
