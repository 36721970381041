import React from "react";

export default class SearchIcon extends React.Component {
  render() {
    return (
      <a className="cps-link" onClick={this.props.toggleSearch}>
        <span className="cps-icon cps-icon-search cps-white" title="Search" />
      </a>
    );
  }
}
