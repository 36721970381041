export function getQuestionLink(version, currentRevision, question) {
  return `#/sme/versions/${version.id}-${
    currentRevision.id
  }/question-bank?search=${btoa(question.name)}`;
}

export function getSourceFormLink(version, currentRevision, form) {
  return `#/sme/versions/${version.id}-${
    currentRevision.id
  }/source-form-builder/groups/abc/forms/${form.id}`;
}

export function getGroupLink(version, currentRevision, group) {
  return `#/sme/versions/${version.id}-${
    currentRevision.id
  }/source-form-builder/groups/${group.id}`;
}

export function getTaxFormLink(version, currentRevision, form) {
  return `#/sme/versions/${version.id}-${currentRevision.id}/tax-forms/edit/${
    form.name
  }`;
}

export function getReportLink(version, currentRevision, report) {
  return `#/sme/versions/${version.id}-${currentRevision.id}/reports/edit/${
    report.id
  }`;
}

export function getSharedValuesLink(version, currentRevision, value) {
  return `#/sme/versions/${version.id}-${
    currentRevision.id
  }/shared-values?search=${btoa(value.key)}`;
}

export function getSectionLink(version, currentRevision, section) {
  return `#/sme/versions/${version.id}-${
    currentRevision.id
  }/source-form-builder/groups/abc/forms/${section.form_id}/sections/${
    section.id
  }`;
}
