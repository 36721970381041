import React from "react";
import Cancelable from "react-disposable-decorator";
import { catchAsyncStacktrace } from "auto-trace";

import auth from "cp-client-auth!sofe";

import { HiZ } from "src/sme-menu.component.js";
import { getEndUserAppUrl } from "./navbar.helpers.js";

@Cancelable
export default class UserMenu extends React.Component {
  state = {
    user: {
      permissions: {
        sme_publish: false,
      },
    },
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      auth
        .getLoggedInUserAsObservable()
        .subscribe(
          user => this.setState(_ => ({ user })),
          catchAsyncStacktrace()
        )
    );
  }

  render() {
    const dropName = "user";
    const redirectUrl = window.encodeURIComponent(window.location.origin);

    const dropdownStatus =
      this.props.activeDropdown === dropName ? "open" : "closed";
    const authUrl = getEndUserAppUrl(window.location);
    return (
      <span>
        <a
          onClick={() => this.props.setDropdown(dropName)}
          className="cps-margin-right-12 cps-link cps-white"
        >
          {this.props.userName}
          <span className="cps-caret cps-white" />
        </a>
        <ul className="cps-list-unstyled">
          <li className={`cps-dropdown cps-${dropdownStatus}`}>
            <HiZ className="cps-dropdown-menu" role="menu">
              {this.state.user.permissions.sme_publish && (
                <li>
                  <a
                    href={`#/sme/manage-team-members`}
                    onClick={() => this.props.closeDropdown()}
                  >
                    Manage Team Members
                  </a>
                </li>
              )}
              <li>
                <a href={authUrl} onClick={() => this.props.closeDropdown()}>
                  Go to Canopy End-User App
                </a>
              </li>
              <li>
                <a
                  href={`#/sme/versions/?manage=1`}
                  onClick={() => this.props.closeDropdown()}
                >
                  Manage Versions
                </a>
              </li>
              <li>
                <a
                  href={`${authUrl}/#/logout?redict_url=${redirectUrl}`}
                  onClick={() => this.props.closeDropdown()}
                >
                  Sign out
                </a>
              </li>
            </HiZ>
          </li>
        </ul>
      </span>
    );
  }
}
