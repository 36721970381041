import { Observable, ReplaySubject } from "rxjs";
import { first, flatMapLatest, map, pluck, tap } from "rxjs/operators";
import { infoToast } from "toast-service!sofe";
import auth from "cp-client-auth!sofe";
import urls from "canopy-urls!sofe";
import { onPusher, fetchAsObservable } from "fetcher!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { getWorkspaceMessage } from "./sme-user.helper.js";

export const smeSetup = () => {
  updateSmeUser().subscribe(() => {});
  onPusher("sme-notifications").subscribe(data => {
    smeUser.pipe(first()).subscribe(oldUser => {
      updateSmeUser().subscribe(user => {
        const message = getWorkspaceMessage(data.workspaceId, oldUser, user);

        if (message) {
          infoToast(message);
        }
      });
    });
  });
};

export const smeUser = new ReplaySubject(1);

export function updateSmeUser() {
  return auth.getLoggedInUserAsObservable().pipe(
    first(),
    flatMapLatest(user => fetchAsObservable(`/sme-users/${user.id}`)),
    pluck("sme-users"),
    tap(resp => smeUser.next(resp)),
    catchAsyncStacktrace()
  );
}

export function getWorkspacesInReview() {
  return Observable.create(observer => {
    const disposable = smeUser.subscribe(user => {
      observer.next(
        user.workspaces.filter(
          ws => ws.status === "inReview" && ws.reviewerId === user.id
        )
      );
    });
  });
}

export function changeActiveWorkspace(activeWorkspace) {
  return patchSmeUser({ activeWorkspace });
}

export function patchSmeUser(user) {
  return smeUser.pipe(
    first(),
    flatMapLatest(oldSmeUser =>
      fetchAsObservable(`${urls.getWebUrl()}/wg/sme-users/${oldSmeUser.id}`, {
        method: "PATCH",
        body: {
          "sme-users": user,
        },
      }).pipe(map(resp => oldSmeUser))
    ),
    tap(oldSmeUser =>
      smeUser.next({
        ...oldSmeUser,
        ...user,
      })
    )
  );
}

export function getSme(id) {
  return fetchAsObservable(`${urls.getWebUrl()}/wg/sme-users/${id}`).pipe(
    pluck("sme-users")
  );
}

export function getWorkspace(id) {
  return fetchAsObservable(`${urls.getWebUrl()}/wg/workspaces/${id}`).pipe(
    pluck("workspaces")
  );
}
