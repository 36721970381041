import React from "react";
import styled from "styled-components";
import Tabs from "src/tabs.component.js";
import SmeMenu from "src/sme-menu.component.js";
import Search from "src/search/search.component.js";
import {
  getDraft,
  flattenRevisions,
  NAV_TABS,
  determineCurrent,
  isTaxPrep,
} from "src/navbar.helpers.js";
import { SelectContext } from "src/selectContext.js";
import {
  getVersions,
  updateVersionObservable,
} from "src/resources/versions.resource.js";
import { get } from "lodash";
import Cancelable from "react-disposable-decorator";

export default Cancelable(
  class Navbar extends React.Component {
    versionRegex = /(\w+-\d+)/;

    state = {
      activeLeft: null,
      activeWidth: null,
      selectedVersion: null,
      selectedUrlPrefix: null,
      revisions: [],
      showSearch: false,
    };

    componentDidMount() {
      this.fetchRevisions();
    }

    fetchRevisions() {
      this.props.cancelWhenUnmounted(
        getVersions().subscribe(versions => {
          const setHash = get(
            window.location.hash.match(this.versionRegex),
            "[0]",
            null
          );

          this.setState({
            revisions: flattenRevisions(versions),
            selectedUrlPrefix:
              setHash || getDraft(versions, this.props.product),
          });
        })
      );
    }

    selectUrlPrefixAndVersion(selected) {
      this.setState({
        selectedVersion: selected.selectedVersion,
        selectedUrlPrefix: selected.selectedUrlPrefix,
      });

      updateVersionObservable(selected.selectedUrlPrefix);
      this.reNav(selected.selectedUrlPrefix);
    }

    reNav(urlSeg) {
      const [octy, ...segs] = window.location.hash.split("/");

      const newHash = segs.reduce((acc, curr) => {
        return this.versionRegex.test(curr)
          ? `${acc}/${urlSeg}`
          : `${acc}/${curr}`;
      }, "");
      if (this.versionRegex.test(newHash)) {
        window.location.hash = newHash;
        window.location.reload();
      }
    }

    toggleSearch() {
      this.setState(prevState => {
        return {
          showSearch: !prevState.showSearch,
        };
      });
    }
    render() {
      const { user } = this.props;

      return (
        <SelectContext.Provider value={this.state.selectedUrlPrefix}>
          <StyledNav>
            <img
              className="logo"
              src="https://cdn.canopytax.com/images/canopy-logo-icon.svg"
            />
            <LeftSide>
              <Tabs
                urlPrefix={this.state.selectedUrlPrefix}
                revisions={this.state.revisions}
                tabs={NAV_TABS}
              />
            </LeftSide>
            <div style={{ flex: 1 }} />
            <RightSide>
              <SmeMenu
                smeUser={this.props.sme}
                toggleSearch={this.toggleSearch.bind(this)}
                user={user}
                selectedVersion={this.state.selectedVersion}
                selectUrlPrefixAndVersion={this.selectUrlPrefixAndVersion.bind(
                  this
                )}
                revisions={this.state.revisions}
                fetchRevisions={this.fetchRevisions.bind(this)}
              />
            </RightSide>
          </StyledNav>
          {this.state.showSearch && (
            <Search
              urlVersionBase={this.state.selectedUrlPrefix}
              close={this.toggleSearch.bind(this)}
            />
          )}
        </SelectContext.Provider>
      );
    }

    setCoords = (left, width) => {
      return this.setState(() => {
        return {
          activeLeft: left,
          activeWidth: width,
        };
      });
    };
  }
);

const StyledNav = styled.div`
  background-color: #52555a;
  position: fixed;
  white-space: nowrap;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 101;
  .logo {
    margin-left: 24px;
    margin-right: 24px;
    background-color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    flex-shrink: 0;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  a.tab {
    color: #fff;
    margin-left: 24px;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  height: 100%;
  .menu-items {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
`;
