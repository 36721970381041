import fetcher from "src/resources/fetcher.resource.js";
import { ReplaySubject } from "rxjs";
import { pluck } from "rxjs/operators";
import { get } from "lodash";

export function getVersions() {
  return fetcher("versions", { noVersions: true }).pipe(pluck("versions"));
}

export const currentVersion = new ReplaySubject(1);

currentVersion.next(get(window.location.hash.match(/(\w+-\d+)/), "[0]", null));

export const updateVersionObservable = version => {
  return currentVersion.next(version);
};
