import React from "react";
import { ErrorBoundary } from "error-logging!sofe";
import Cancelable from "react-disposable-decorator";
import { HashRouter } from "react-router-dom";
import { get } from "lodash";

import auth from "cp-client-auth!sofe";
import { fetchAsObservable } from "fetcher!sofe";
import { pluck, switchMap, tap } from "rxjs/operators";

import NavBar from "src/navbar.component.js";

@ErrorBoundary({ featureName: "sme-navbar", noStrictMode: false })
@Cancelable
class NavbarContainer extends React.Component {
  state = {
    user: {},
    sme: {},
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      auth
        .getLoggedInUserAsObservable()
        .pipe(
          tap((user) => this.setState({ user })),
          switchMap((user) =>
            fetchAsObservable(`/sme-users/${user.id}`).pipe(pluck("sme-users"))
          )
        )
        .subscribe((sme) => this.setState((_) => ({ sme })))
    );
  }

  render() {
    return (
      <HashRouter>
        <NavBar
          user={this.state.user}
          sme={this.state.sme}
          currentHash={this.props.location}
          product={get(this.state, "sme.product")}
        />
      </HashRouter>
    );
  }
}

export default NavbarContainer;
