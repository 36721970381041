import React from "react";
import { determineCurrent } from "src/navbar.helpers.js";
import { NavLink } from "react-router-dom";
import Cancelable from "react-disposable-decorator";
import { Helmet } from "react-helmet";

@Cancelable
export default class Tabs extends React.Component {
  state = {
    activeTab: determineCurrent(window.location.hash),
  };

  onHashChange = () => {
    const current = determineCurrent(window.location.hash);
    this.setState({ activeTab: current });
  };

  componentDidMount() {
    window.addEventListener("hashchange", this.onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.onHashChange);
  }

  render() {
    const { tabs } = this.props;
    const baseUrl = `sme/versions/${this.props.urlPrefix}/`;
    const activeTabInNavLinks =
      tabs.filter((link) => link.url === this.state.activeTab).length > 0;

    return (
      <div style={tabStyle}>
        {tabs.map((link, index) => {
          return (
            <span key={link.title}>
              {(this.state.activeTab === link.url || !activeTabInNavLinks) && (
                <Helmet>
                  <title>
                    {this.state.activeTab === link.url
                      ? link.title
                      : "SME Tool"}
                  </title>
                </Helmet>
              )}
              <NavLink
                to={
                  link.isRootUrl
                    ? `${link.url}/versions/${this.props.urlPrefix}`
                    : `/${baseUrl}${link.url}`
                }
                isActive={() => this.state.activeTab === link.url}
                activeStyle={{
                  textDecoration: "none",
                  borderBottom: "thick solid #00BF4B",
                  paddingBottom: 7,
                }}
                style={{
                  color: "#fff",
                  marginLeft: "24px",
                }}
              >
                {link.title}
              </NavLink>
            </span>
          );
        })}
      </div>
    );
  }
}

const tabStyle = {
  display: "flex",
  alignItems: "center",
  zIndex: "99",
  position: "relative",
  marginLeft: "-24px",
  width: "100%",
};
