import React from "react";
import { Link } from "react-router-dom";
import { partial } from "lodash";
import styled from "styled-components";
import SearchIcon from "src/search/search-icon.component.js";
import UserMenu from "src/user-menu.component.js";
import VersionSelector from "src/versions/version-selector.component.js";
import Cancelable from "react-disposable-decorator";

class SmeMenu extends React.Component {
  state = {
    urlVersionBase: null,
    selectedName: null,
    activeDropdown: false,
  };

  selectIt(versionId, readableName, urlFormat) {
    this.setState({
      selectedName: readableName,
      urlVersionBase: urlFormat,
    });

    this.props.selectUrlPrefixAndVersion({
      selectedVersion: versionId,
      selectedUrlPrefix: urlFormat,
    });
  }

  closeDropdown() {
    this.setState({ activeDropdown: false });
  }

  setDropdown(dropdownName) {
    this.props.fetchRevisions();
    this.setState(() => ({
      activeDropdown: dropdownName,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.bodyClicked);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.bodyClicked);
  }

  render() {
    return (
      <div
        className="cps-pull-right cps-inline-block menu-items"
        ref={this.setWrapper}
      >
        <VersionSelector
          setDropdown={this.setDropdown.bind(this)}
          activeDropdown={this.state.activeDropdown}
          urlVersionBase={this.state.urlVersionBase}
          urlPrefix={this.props.urlPrefix}
          revisions={this.props.revisions}
          selectIt={this.selectIt.bind(this)}
        />
        <Link className="cps-link" to={`/sme/help`}>
          <span className="cps-icon cps-icon-help cps-white" title="Help" />
        </Link>
        <SearchIcon toggleSearch={partial(this.props.toggleSearch, true)} />
        <UserMenu
          userName={this.props.user.name}
          setDropdown={this.setDropdown.bind(this)}
          activeDropdown={this.state.activeDropdown}
          closeDropdown={this.closeDropdown.bind(this)}
        />
      </div>
    );
  }

  bodyClicked = e => {
    if (
      this.wrapper &&
      !this.wrapper.contains(e.target) &&
      this.state.activeDropdown
    ) {
      return this.setState((prevState, props) => ({
        activeDropdown: false,
      }));
    }
  };

  setWrapper = el => {
    this.wrapper = el;
  };
}

export const HiZ = styled.ul`
  position: absolute;
  z-index: 101;
`;

export default Cancelable(SmeMenu);
